import {deleteCookie, getCookie, setSessionCookie} from './utils/cookies';

const COUPON_PROPERTY = 'pl_coupon';

const getCookieCoupon = function getCookieCoupon() {
  if (typeof window === 'undefined') return null; // No SSR

  const couponCookie = getCookie(COUPON_PROPERTY);

  return couponCookie;
};

const getQueryCoupon = function getQueryCoupon(query) {
  let {pl_coupon: newCoupon = ''} = query;

  // sanitize incoming coupon code
  newCoupon && (newCoupon = newCoupon.replace(/[^a-zA-Z0-9!.?#%@$_-]/, ''));

  return newCoupon;
};

const getCampaignCoupon = function getCampaignCoupon(query) {
  // eslint-disable-next-line camelcase
  const {active_campaigns} = query; // The value is camelcase to keep the same value as Rails
  const currentDate = new Date();
  const startCampaignDate = new Date('11-27-2024 15:00:00 GMT-0000');
  const endCampaignDate = new Date('12-04-2024 05:59:59 GMT-0000');
  const isCampaignDateActive = currentDate >= startCampaignDate && currentDate <= endCampaignDate;
  // eslint-disable-next-line camelcase
  const isCampaignParamActive = !!active_campaigns?.includes('cyber-week-24');

  if (isCampaignDateActive || isCampaignParamActive) {
    return 'cyber33off';
  }

  return null;
};

const getCoupon = function getCoupon(query) {
  const queryCoupon = getQueryCoupon(query);
  const cookieCoupon = getCookieCoupon();
  const campaignCoupon = getCampaignCoupon(query);

  if (queryCoupon) {
    return {
      code: queryCoupon,
      source: 'query',
    };
  }

  if (cookieCoupon) {
    return {
      code: cookieCoupon.coupon_code,
      source: 'cookie',
    };
  }

  if (campaignCoupon) {
    return {
      code: campaignCoupon,
      source: 'campaign',
    };
  }

  return {};
};

const deleteCouponCookie = function deleteCouponCookie() {
  deleteCookie(COUPON_PROPERTY);
};

const saveCouponCookie = async function saveCouponCookie(couponCode) {
  // since api is not properly saving coupon for rails app - lets hack it, for now.
  const url = `${window.location.origin}/coupons/${couponCode}/sign/`;
  const res = await fetch(url, {
    method: 'POST',
    credentials: 'include',
  });
  const json = await res.json();
  const encodedCoupon = encodeURIComponent(JSON.stringify(json.coupon));
  setSessionCookie(COUPON_PROPERTY, encodedCoupon);
};

export {getCoupon, saveCouponCookie, deleteCouponCookie};
