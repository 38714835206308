import {useRouter} from 'next/router';
import {useState, createContext, useEffect, useMemo, useContext} from 'react';
import PropTypes from 'prop-types';
import {deleteCouponCookie, getCoupon} from '../lib/coupons';
import pricingDataProcessing from '../lib/prices';
import {UserContext} from './UserContext';
import useNotifications from '../hooks/useNotifications';

export const initialPrice = {
  price: '',
  currency: 'USD' || 'BRL',
  annual_monthly_price: '',
  annual_monthly_price_in_cents: 0,
  annual_price: '',
  annual_price_in_cents: 0,
  monthly_price: '',
  monthly_price_in_cents: 0,
  coupon_valid: false,
  coupon_scope: '',
  discounted_price: 0,
  discounted_prices: {
    monthly_price_in_cents: 0,
    monthly_price: '',
    annual_price_in_cents: 0,
    annual_price: '',
    annual_monthly_price_in_cents: 0,
    annual_monthly_price: '',
  },
};

export const PriceContext = createContext({
  prices: initialPrice,
  setPrices: () => null,
  couponStatus: '',
  setCouponStatus: () => null,
});

export default function PriceProvider({children}) {
  const [prices, setPrices] = useState(initialPrice);
  const {currentUser} = useContext(UserContext);
  const router = useRouter();
  const couponInfo = getCoupon(router.query);
  const Notification = useNotifications();

  const getPrices = async () => {
    const newPrices = await pricingDataProcessing(couponInfo);

    if (newPrices !== null) {
      setPrices(prevState => ({...prevState, ...newPrices}));
    }
  };

  useEffect(() => {
    if (
      currentUser?.has_subscription === true &&
      couponInfo?.code &&
      couponInfo?.source !== 'campaign'
    ) {
      Notification.info({
        message: 'This offer is for new users only.',
        duration: 3000,
      });
      deleteCouponCookie();
    }
  }, [currentUser?.loggedIn]);

  useEffect(() => {
    router.isReady && prices.price === '' && getPrices();
  }, [router.isReady]);

  const value = useMemo(() => ({prices, setPrices}), [prices]);

  return <PriceContext.Provider value={value}>{children}</PriceContext.Provider>;
}

PriceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
