import {useEffect, useContext} from 'react';
import {UserContext} from '../../context/UserContext';
import {VariantContext} from '../../context/VariantContext';

export default function CtaVariantHelper() {
  const {currentUser} = useContext(UserContext);
  const {ctalevel, setVariations} = useContext(VariantContext);

  /* when currentUser has a plan, setup the ctalevel variant */
  useEffect(() => {
    if (currentUser?.is_past_due) {
      setVariations({ctalevel: 'pastdue'});
    } else if (currentUser?.status === 'Churned') {
      setVariations({ctalevel: 'churned'});
    } else if (currentUser?.plan && ctalevel !== currentUser?.plan) {
      setVariations({ctalevel: currentUser?.plan});
    } else if (currentUser?.username) {
      setVariations({ctalevel: 'regular'});
    } else {
      setVariations({ctalevel: 'visitor'});
    }
  }, [currentUser?.plan]);

  return null;
}
